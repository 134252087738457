import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

declare global {
  interface Window {
    Twitch: any;
  }
}

export const Stream = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("src", "https://embed.twitch.tv/embed/v1.js");
    script.addEventListener("load", () => {
      new window.Twitch.Embed("twitch", {
        autoplay: false,
        channel: "randysbarbershop",
        height: "100%",
        layout: "video",
        theme: "dark",
        width: "100%",
      });
    });
    document.body.appendChild(script);
  });

  return (
    <Container as="section">
      <h1 className="display">Stream</h1>
      <p>
        If the stream is live, you may see how many people are currently
        waiting.
      </p>
      <div className="video">
        <div id="twitch" />
      </div>
    </Container>
  );
}
