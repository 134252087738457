import React from "react";

export const Copyright = () => (
  <div className="copyright">
    <p>
      &copy; 2020 - {new Date().getFullYear()} Randys Barbershop Inc. All Rights
      Reserved.
    </p>
    <p>
      Photos by{" "}
      <a
        href="https://thischristography.com/?utm_source=Randys+Barbershop&utm_medium=website"
        target="_blank"
        rel="noopener noreferrer"
      >
        This Christography
      </a>
    </p>
  </div>
);
