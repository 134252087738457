import React from "react";
import { Container } from "react-bootstrap";
import { map } from "lodash";
import "./Pricing.css";

type Items = { [item: string]: number | string };

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const Pricing = () => {
  const men: Items = {
    Haircuts: 25,
    "Razor Fades": 30,
    "Hot Towel Shave": 25,
    "Beard Line Up": 10,
    "Credit Card Charge": 2,
  };

  const item = (price: number | string, item: string) => (
    <div className="item" key={item}>
      <span>{item}</span>
      <span>
        <span>
          {Array.from({ length: 200 }).join('.')}
        </span>
      </span>
      <span>{typeof price === "number" ? USDollar.format(price) : price}</span>
    </div>
  );

  return (
    <Container as="section">
      <h1 className="display">Pricing</h1>
      <h2 className="tagline mt-5">Men Services</h2>
      {map(men, item)}
    </Container>
  );
};
