import React from "react";
import { useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "./Booked.css";

function formatDateTime(startString: string | null, endString: string | null) {
  const start = startString === null ? null : new Date(startString);
  const end = endString === null ? null : new Date(endString);
  if (!start) {
    return "Date and Time not provided. Please refer to your email for details.";
  }

  const options: any = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = start.toLocaleDateString("en-US", options);

  const startTime = start.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
  const endTime = end
    ? end.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric" })
    : null;

  return endTime
    ? `${formattedDate}\n${startTime} to ${endTime}`
    : `${formattedDate} @ ${startTime}`;
}

function normalizePhoneNumber(phoneNumber: string | null) {
  if (!phoneNumber) return "";

  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]} - ${match[3]}`;
  }

  return phoneNumber;
}

export const Booked = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const barber = params.get("assigned_to");

  // const eventTypeUuid = params.get('event_type_uuid');
  const eventTypeName = params.get("event_type_name");
  const eventStartTime = params.get("event_start_time");
  const eventEndTime = params.get("event_end_time");
  const eventTime = formatDateTime(eventStartTime, eventEndTime);

  const inviteeUuid = params.get("invitee_uuid");
  const inviteeFullName = params.get("invitee_full_name");
  const inviteeEmail = params.get("invitee_email");
  const inviteePhoneNumber = params.get("answer_1");
  // const textReminderNumber = params.get('text_reminder_number');

  return (
    <div id="booked" className="splash">
      <Modal show={true} backdrop="static" keyboard={false} size="lg" centered>
        <Modal.Body className="text-center">
          <div>
            <h1>
              {eventTypeName} with {barber}
            </h1>
            <p style={{ whiteSpace: "pre", fontSize: "1.1rem" }}>{eventTime}</p>
            <p>
              <small>
                A confirmation email has also been sent to the email you
                provided.
              </small>
            </p>
          </div>

          <div className="my-4">
            <h2>Your Information</h2>
            <p>
              <strong>{inviteeFullName}</strong>
              <br />
              <span>{inviteeEmail}</span>
              <br />
              <span>{normalizePhoneNumber(inviteePhoneNumber)}</span>
            </p>
          </div>

          <div className="my-4">
            <h2>Barbershop Location</h2>
            <p>
              <a
                href="https://goo.gl/maps/LGaQrzgE6eFyz8zq7"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#0d6efd !important", border: "none" }}
              >
                1439 Wyoming Blvd. NE
                <br />
                Albuquerque, NM 87112
              </a>
            </p>
          </div>

          <div className="my-4">
            <h2>Cancellation or Rescheduling</h2>
            <p>
              If you need to cancel or reschedule, please use one of the buttons
              below and follow the instructions on the screen.
            </p>
            <div className="actions">
              <Button
                href={`https://calendly.com/cancellations/${inviteeUuid}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Cancel
              </Button>
              <Button
                href={`https://calendly.com/reschedulings/${inviteeUuid}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Reschedule
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
