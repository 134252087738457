import React from "react";
import { Modal } from "react-bootstrap";
import "./NotFound.css";

export const NotFound = () => {
  return (
    <div id="not-found" className="splash">
      <Modal show={true} backdrop="static" keyboard={false} size="lg" centered>
        <Modal.Body className="text-center">
          <div>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>The page you are looking for does not exist.</p>
            <p>
              <a href="/" className="btn btn-primary">
                Go Home
              </a>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
