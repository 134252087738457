import React from "react";
import { Carousel, Button } from "react-bootstrap";
import "./Splash.css";

export const Splash = () => (
  <div className="splash">
    <Carousel
      controls={false}
      fade={true}
      indicators={false}
      pause={false}
      interval={10000}
    >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/splashes/ThisChristography-20200613.jpg"
          alt="Randy's Barber Shop inside"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/splashes/ThisChristography-20200613-2.jpg"
          alt="Close up of barber chair"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/splashes/ThisChristography-20200613-4.jpg"
          alt="Randy's Barber Shop inside"
        />
      </Carousel.Item>
    </Carousel>
    <div className="content">
      <img
        className="logo"
        src="/assets/randysbarbershop.png"
        alt="Randy's Barber Shop logo"
      />
      <div className="bookings">
        <Button
          className="booking"
          href="https://calendly.com/rj-the-barber/haircuts"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book Online With RJ
        </Button>
        <Button
          className="booking"
          href="https://calendly.com/drews-cuts"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book Online With Drew
        </Button>
      </div>
    </div>
  </div>
);
