import React from "react";

export const Contact = () => (
  <div className="mb-5">
    <h4>Contact Us</h4>
    <p>
      <a href="tel:5052969284">(505) 296 - 9284</a>
    </p>
    <p>
      <a
        href="https://goo.gl/maps/LGaQrzgE6eFyz8zq7"
        target="_blank"
        rel="noopener noreferrer"
      >
        1439 Wyoming Blvd. NE
        <br />
        Albuquerque, NM 87112
      </a>
    </p>
  </div>
);
