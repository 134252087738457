import React from "react";
import { Container } from "react-bootstrap";

export const About = () => (
  <Container as="section">
    <h1 className="display">Randy's Barber Shop</h1>
    <h2 className="tagline">Est. 1973</h2>
    <p>
      Established in 1973 by Randy Sanchez, Randy's Barber Shop has been located
      on Wyoming Blvd serving its community with hot towel shaves and
      gentlemen's cuts 6 days a week, month to month, year to year. Randy's
      Barber Shop continues to provide its services for the four generations of
      families and friends that have made the barber shop their own personal get
      away. In 2020, Randy's Barber Shop became Randy's Barber Shop Inc. Big RJ
      the Barber, new owner-operator and Master Barber, bought the business and
      made it Incorporated to ensure a work place for Randy until he is ready to
      retire. We are looking to keep Randy's a staple, not only in the
      fast-growing Uptown area, but a Landmark for the men in the barbering
      profession and to keep that old school barbering heritage alive. Randy's
      Barber Shop Inc. will continue to provide gentlemen's cuts, full hot towel
      shaves, and beard line ups with that old-fashioned feel.
    </p>
    <p>
      Randy's Barber shop Inc. Where a great cut and a smile can really make you
      feel at home.
    </p>
  </Container>
);
