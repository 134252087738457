import React from "react";
import { Container } from "react-bootstrap";
import {
  About,
  Contact,
  Copyright,
  Hours,
  Pricing,
  Splash,
  Stream,
} from "../components";

export const Home = () => (
  <>
    <Splash />
    <main>
      <About />
      <Pricing />
      <Stream />
    </main>
    <footer>
      <Container as="section" className="text-center">
        <Hours />
        <Contact />
        <Copyright />
      </Container>
    </footer>
  </>
);
